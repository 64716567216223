import * as e from "../../data/enums";
import * as image from "../../data/constants/images";
import React, { useState, useCallback } from "react";
import { handleError } from "../../utils/sharedUtils";
import { Link } from "react-router-dom";
import Loading from "../../components/Loading";
import { useTeacher } from "../../context/UserContext";
import { isSafariBrowser } from "../../utils/deviceCheck";
import { FirebaseManager } from "../../services/firebase/FirebaseManager";
import { useTranslation } from "react-i18next";

export default function Login() {
	const { setTeacher } = useTeacher();
	const [emailLogin, setEmailLogin] = useState<string>("");
	const [passwordLogin, setPasswordLogin] = useState<string>("");
	const [loading, setLoading] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string>("");
	const [showPassword, setShowPassword] = useState(false);
	const { t } = useTranslation("LoginPages");

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	const logInTeacher = useCallback(async () => {
		setLoading(true);
		try {
			await FirebaseManager.login(emailLogin, passwordLogin, setTeacher);
		} catch (error: any) {
			setErrorMessage(handleError(error).userMessage);
		} finally {
			setLoading(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [emailLogin, passwordLogin]);

	return (
		<main className={`loginSignup ${isSafariBrowser() ? "is-safari" : ""}`}>
			{loading && <Loading />}
			{errorMessage && <div className="error">{errorMessage}</div>}
			<form
				id="login"
				onSubmit={(e) => {
					e.preventDefault();
					logInTeacher();
				}}
			>
				<label>
					{t("email")}
					<input
						id="email"
						className="text-area"
						type="text"
						value={emailLogin}
						onChange={(e) => setEmailLogin(e.target.value)}
						required
						autoComplete="email"
					/>
				</label>
				<label className="passwordField">
					{t("password")}
					<input
						id="password"
						className="text-area"
						type={showPassword ? "text" : "password"}
						value={passwordLogin}
						onChange={(e) => setPasswordLogin(e.target.value)}
						required
						autoComplete="current-password"
					/>
					<img
						onClick={togglePasswordVisibility}
						className="eyeIcon"
						src={showPassword ? image.ICONS[e.Icons.EYE_OPEN] : image.ICONS[e.Icons.EYE_CLOSED]}
						alt=""
					/>
				</label>
				{!loading && <button type="submit">{t("logIn")}</button>}
			</form>
			<div className="flexRow">
				<Link target="blank" className="legalDisclaimer" to="https://auriseartraining.com/Legal">
					{t("loginDisclaimer")}
				</Link>
				{/* <Link to="/signup">Or Sign Up</Link> */}
				<Link to="/forgotPassword">{t("forgotPassword")}</Link>
			</div>
		</main>
	);
}
