import React, { useState } from "react";
import * as i from "../../data/types";
import * as e from "../../data/enums";
import Loading from "../../components/Loading";
import { useTeacherLoggedIn } from "../../context/UserContext";
import fetchStudentAPI from "../../services/api/fetchStudentAPI";
import updateStudentSubscriptionAPI from "../../services/api/updateStudentSubscription";
import deleteStripeAccountAPI from "../../services/api/deleteStripeAccountAPI";
import { capitalizeFirstLetter } from "../../utils/sharedUtils";

export default function UpgradeStudent(): JSX.Element {
	const [student, setStudent] = useState<i.User | null>(null);
	const { teacher } = useTeacherLoggedIn();
	const [emailToSearch, setEmailToSearch] = useState<string>("");
	const [loading, setLoading] = useState<boolean>(false);

	const fetchStudentByEmail = async () => {
		try {
			setLoading(true);
			const student = await fetchStudentAPI(emailToSearch);
			setStudent(student);
		} catch (error) {
			console.error("Error fetching student:", error);
		} finally {
			setLoading(false);
		}
	};

	const updateStudentSubscriptionPlan = async (plan: "student" | "free" | "monthly") => {
		if (!student || !student.Email) return console.error("Student data is missing");

		try {
			await updateStudentSubscriptionAPI(student.Email, plan);
			// console.log("Subscription plan updated to " + plan + " successfully");
			await fetchStudentByEmail();
		} catch (error) {
			console.error("Error updating subscription plan to " + plan + ": ", error);
		}
	};

	const deleteStripeAccount = async () => {
		// Confirmation dialog
		const confirmDelete = window.confirm("Are you sure you want to cancel this Stripe subscription?");
		if (!confirmDelete) return console.log("Stripe account deletion cancelled.");

		try {
			if (!student || !student.Email || !student.id) return console.error("Student data is missing");
			await deleteStripeAccountAPI(student.Email, student.id);
			console.log("Successfully deleted Stripe account for " + student?.Email);
			await fetchStudentByEmail();
		} catch (error) {
			console.error("Error deleting Stripe account for " + student?.Email + ": ", error);
		}
	};
	if (teacher?.Email !== "teacher1@school1.com") return <></>;
	return (
		<div className="basicCard">
			{loading && <Loading />}
			<div>
				<input
					type="email"
					placeholder="Enter student's email"
					value={emailToSearch}
					onChange={(e) => setEmailToSearch(e.target.value.toLocaleLowerCase().trim())}
				/>
				<br /> <br />
				<button onClick={fetchStudentByEmail}>Search Student</button>
			</div>
			{student && (
				<>
					<br />
					<div className="basicCard">
						<table>
							<tbody>
								<tr>
									<td>Name</td>
									<td>: {student.FirstName + " " + student.LastName}</td>
								</tr>
								<tr>
									<td>Email</td>
									<td>: {student.Email}</td>
								</tr>
								{student.SubscriptionPlan === e.Subscription.STUDENT && (
									<tr>
										<td>Student number</td>
										<td>: {student.StudentNumber}</td>
									</tr>
								)}
								<tr>
									<td>Subscription Plan</td>
									<td>: {capitalizeFirstLetter(student.SubscriptionPlan)}</td>
								</tr>
							</tbody>
						</table>

						<br />
						<button onClick={deleteStripeAccount}>Cancel Stripe Subscription</button>
						<br />
						<br />
						{student.SubscriptionPlan !== e.Subscription.STUDENT && (
							<>
								<button onClick={() => updateStudentSubscriptionPlan("student")}>
									Set Subscription to Student
								</button>
								<br />
								<br />
							</>
						)}
						{student.SubscriptionPlan !== e.Subscription.FREE && (
							<>
								<button onClick={() => updateStudentSubscriptionPlan("free")}>
									Set Subscription to Free
								</button>
								<br />
								<br />
							</>
						)}
						{student.SubscriptionPlan !== e.Subscription.MONTHLY && (
							<>
								<button onClick={() => updateStudentSubscriptionPlan("monthly")}>
									Set Subscription to Monthly
								</button>
							</>
						)}
					</div>
				</>
			)}
		</div>
	);
}
