import { convertDateToString, parseDate } from "../../utils/dates";
import { spaceBeforeCapitals } from "../../utils/sharedUtils";
import * as t from "../../data/types";

export function calculateDaysLeft(deadline: any) {
	const parsedDate = parseDate(deadline);
	const today = new Date();
	const diffTime = Math.abs(parsedDate.getTime() - today.getTime());
	const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
	return diffDays;
}

export function parseTargetData(selectedClass: t.Class | undefined, targets: t.Target[] | undefined): string[][] {
	const targetData: string[][] = [];
	if (!selectedClass || !targets) return targetData;
	targets.map((targets) => {
		const module = targets.Module;
		const difficulty = targets.Difficulty;
		const percentage = targets.Percentage ? targets.Percentage.toString() : "0";
		const deadline = convertDateToString(targets.Deadline, "dd-mm-yyyy");
		const daysLeft = calculateDaysLeft(targets.Deadline).toString();
		const successRate = calculateSuccesRate(selectedClass, module, difficulty).toString();
		const targetID = targets.id;
		targetData.push([module, difficulty, percentage, deadline, daysLeft, successRate, targetID]);
		return null;
	});
	return targetData;
}

function calculateSuccesRate(classData: t.Class, module: string, difficulty: string): number {
	let successRate = 0;

	const targetedProgress =
		getFutureTargets(classData).filter((target) => target?.Module === module)[0]?.Percentage || 0;

	classData?.Students?.forEach((student) => {
		const moduleProgress = student.Progress?.modules[module]?.difficulties[difficulty]?.DifficultyTotal || 0;
		if (moduleProgress >= targetedProgress) successRate++;
	});
	return Math.round((successRate / classData?.Students?.length) * 100);
}

export function getFutureTargets(selectedClass: t.Class | undefined): t.Target[] {
	const today = new Date();
	// Normalize today's date to remove the time component
	today.setHours(0, 0, 0, 0);
	if (!selectedClass || !selectedClass.Targets) return [];

	const targets = selectedClass.Targets.filter((target: t.Target) => {
		const deadline = parseDate(target.Deadline);
		if (!(deadline instanceof Date) || isNaN(deadline.getTime())) {
			console.warn(`Invalid deadline date for target: ${JSON.stringify(target)}`);
			return false;
		}
		// Normalize deadline date to remove the time component
		deadline.setHours(0, 0, 0, 0);
		return deadline > today;
	});
	return targets;
}
