import React, { useMemo, useState } from "react";
import useTableSort from "../../hooks/useTableSort";
import * as t from "../../data/types";
import { parseDate } from "../../utils/dates";
import { clamp, spaceBeforeCapitals } from "../../utils/sharedUtils";
import { useModal } from "../../hooks/useModal";
import SortableTable from "../../components/SortableTable";
import { CommonModal, ModalNavigation } from "../../components/CommonModal";
import Progress from "./Progress";
import { getFutureTargets } from "../Targets/utils";
import { useTranslation } from "react-i18next";

export default function Students({ selectedClass }: { selectedClass: t.Class | undefined }): JSX.Element {
	const { showModal, setShowModal, handleShowModal, selectedItem, setSelectedItem } = useModal<t.User>();

	const students = selectedClass?.Students;
	const studentsData = useMemo(() => parseStudentData(students, selectedClass), [students, selectedClass]);

	const { sortedData, handleSort, sortConfig } = useTableSort(studentsData);

	const { t } = useTranslation("Overview");

	if (!selectedClass) return <></>;
	return (
		<div className="overviewTable">
			<SortableTable
				columns={[
					t("name"),
					t("practiceTime"),
					t("targetCompletion"),
					t("weeklyProgress"),
					t("monthlyProgress"),
					t("totalProgress"),
					t("avgGrade"),
				]}
				data={sortedData}
				handleSort={handleSort}
				sortConfig={sortConfig}
				rowOnClick={(studentID) => {
					const student = selectedClass.Students?.find((student) => student.id === studentID);
					handleShowModal(student);
				}}
			/>
			<StudentModal
				student={selectedItem}
				students={students}
				setSelectedStudent={setSelectedItem}
				selectedClass={selectedClass}
				showModal={showModal}
				setShowModal={setShowModal}
			/>
		</div>
	);
}

function parseStudentData(students: t.User[] | undefined, classData: t.Class | undefined): string[][] {
	const studentsData: string[][] = [];
	if (!students || !classData) return studentsData;
	const futureTargets = getFutureTargets(classData);
	students.map((student) => {
		const name = student.FirstName + " " + student.LastName;
		const practiceTime = (Math.round(student.WeeklyStats.PracticeTime / 6) / 10).toString();
		const target = calculateTargetProgress(futureTargets, student);
		const progressLastWeek = student.WeeklyStats.Progress.toString();
		const progressLastMonth = student.MonthlyStats?.Progress.toString();
		const progressTotal = student.Progress?.Total.toString();
		const avgGrade = "-";
		const id = student.id;
		if (id)
			studentsData.push([
				name,
				practiceTime,
				target,
				progressLastWeek,
				progressLastMonth,
				progressTotal,
				avgGrade,
				id,
			]);
		return null;
	});
	return studentsData;
}

export function calculateTargetProgress(targets: t.Target[] | undefined, student: t.User | undefined): string {
	if (!targets || !student || !student.Progress || targets.length === 0) return "-";
	const targetsToCalculate = targets.filter((target) => parseDate(target.Deadline) > new Date());
	if (targetsToCalculate.length === 0) return "-";
	let targetProgress = 0;
	for (const target of targetsToCalculate) {
		const targetedProgress = target.Percentage;
		const progress = student.Progress.modules[target.Module]?.difficulties[target.Difficulty]?.DifficultyTotal || 0;
		const progressPercentage = Math.round((progress / targetedProgress) * 100);
		targetProgress += clamp(0, progressPercentage, 100);
	}
	targetProgress = Math.round(targetProgress / targetsToCalculate.length);
	return targetProgress.toString();
}

function StudentModal({
	student,
	students,
	setSelectedStudent,
	selectedClass,
	showModal,
	setShowModal,
}: {
	student: t.User | undefined;
	students: t.User[] | undefined;
	setSelectedStudent: React.Dispatch<React.SetStateAction<t.User | undefined>>;
	selectedClass: t.Class;
	showModal: boolean;
	setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element {
	if (!student || !students) return <></>;

	const studentIndex = students?.findIndex((s) => s.id === student?.id);
	const navigateTarget = (direction: "left" | "right") => {
		const newIndex = direction === "left" ? studentIndex - 1 : studentIndex + 1;
		if (newIndex < 0 || newIndex > students.length - 1) return;
		setSelectedStudent(students[newIndex]);
	};

	const navigationLabel = (
		<div className="navigationLabel">
			<h2>{spaceBeforeCapitals(student.FirstName + " " + student.LastName)}</h2>
			<p>{student.Email}</p>
			{/* {student.StudentNumber && <p>{student.StudentNumber}</p>} */}
		</div>
	);

	return (
		<CommonModal showModal={showModal} setShowModal={setShowModal}>
			<ModalNavigation
				currentIndex={studentIndex}
				totalItems={students.length}
				onNavigate={navigateTarget}
				navigationLabel={navigationLabel}
			/>
			<Progress user={student} selectedClass={selectedClass} />
		</CommonModal>
	);
}
