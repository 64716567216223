import React, { useState, useEffect, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./styles/style.css";
import Header from "./components/Header";
import Navigation from "./components/Footer";
import Login from "./pages/notLoggedInRoutes/Login";
import ManageClasses from "./pages/ManageClasses";
import Contact from "./pages/Contact";
import Support from "./pages/Support";
import DBManager from "./features/DBManager/DBManager";
import Loading from "./components/Loading";
import * as i from "./data/types";
import { TeacherLoggedInProvider, useTeacher } from "./context/UserContext";
import CustomAlert from "./components/Alert";
import Overview from "./pages/Overview";
import ErrorPage from "./pages/ErrorPage";
import retrieveDataFromStorage from "./services/firebase/retrieveDataFromStorage";
import { FirebaseManager } from "./services/firebase/FirebaseManager";
import Settings from "./pages/Settings";
import ErrorBoundary from "./context/ErrorBoundary";
import { useAlert } from "./context/AlertContext";
import ForgotPassword from "./pages/notLoggedInRoutes/ForgotPassword";
// import { useTranslation } from "react-i18next";

export default function App() {
	const { showAlert } = useAlert();
	const { teacher, setTeacher } = useTeacher();
	const [classData, setClassData] = useState<i.Class[] | null>(null);
	const [loading, setLoading] = useState<boolean>(false);

	// const { i18n } = useTranslation();
	// const changeLanguage = (language: string | undefined) => {
	// 	i18n.changeLanguage(language);
	// };

	useEffect(() => {
		// console.log("App useEffect - Initial load");
		const hasRetrievedUser = retrieveDataFromStorage(teacher, setTeacher, setClassData);

		let unsubscribeFn: () => void = () => {};

		try {
			setLoading(true);
			// only monitor auth state if user has not been retrieved from storage
			(async () => {
				// TODO: if we want to reduce API calls, we can use the following code
				// TODO: make sure it doesn't effect if user is already loggid in on other device
				// const idToken = await FirebaseManager.getAuthIdToken();
				// if (hasRetrievedUser && idToken) return;
				unsubscribeFn = await FirebaseManager.monitorAuthState(setTeacher, setClassData);
			})();
			return () => {
				unsubscribeFn();
			};
		} catch (error) {
			console.error("Error in App.tsx useEffect:", error);
		} finally {
			setLoading(false);
			// console.log("Loading state set to false");
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		console.log("App useEffect - Teacher or ClassData changed", { teacher, classData });
		if (teacher && !classData) {
			console.log("Teacher is set but classData is null, setting loading to true");
			setLoading(true);
			// console.log(
			// 	"Teacher is set but classData is null, setting loading to false because it might be a new teacher without classes"
			// );
			// setLoading(false);
		} else if (teacher && classData) {
			console.log("Both teacher and classData are set, setting loading to false");
			setLoading(false);
		} else {
			console.log("Either teacher or classData is null, not changing loading state");
		}
	}, [teacher, classData]);

	const [clickOutside, setClickOutside] = useState(false);
	const scrollContentRef = React.createRef<HTMLDivElement>();

	// to make sure scroll works, also when mouse is on left or right div instead of center div
	const handleScroll = (e: React.WheelEvent) => {
		if (scrollContentRef?.current) {
			const { deltaY } = e;
			scrollContentRef.current.scrollTop += deltaY;
		}
	};

	// to make sure user can click outside of modal to close it
	const handleDivClick = () => {
		setClickOutside(!clickOutside);
	};

	return (
		<Router>
			<Suspense fallback={<Loading />}>
				<div className="App">
					<div className="left" onWheel={handleScroll} onClick={handleDivClick}></div>
					<div className="center">
						<Header />
						{showAlert && <CustomAlert />}
						{loading ? (
							<main>
								<Loading />
							</main>
						) : !teacher ? (
							<NotLoggedInRoutes />
						) : (
							<TeacherLoggedInProvider
								teacher={teacher}
								setTeacher={setTeacher}
								classData={classData}
								setClassData={setClassData}
								loading={loading}
								setLoading={setLoading}
								clickOutside={clickOutside}
								scrollContentRef={scrollContentRef}
							>
								<LoggedInRoutes />
							</TeacherLoggedInProvider>
						)}
						<Navigation />
					</div>
					<div className="right" onWheel={handleScroll} onClick={handleDivClick}></div>
					{/* <button onClick={() => changeLanguage("en")}>English</button>
					<button onClick={() => changeLanguage("de")}>Deutsch</button> */}
				</div>
			</Suspense>
		</Router>
	);
}

function NotLoggedInRoutes() {
	return (
		<Routes>
			<Route path="/*" element={<Login />} />
			<Route path="/login" element={<Login />} />
			<Route path="/forgotPassword" element={<ForgotPassword />} />
			<Route path="/404" element={<ErrorPage message="Page not found" />} />
			<Route path="/contact" element={<Contact />} />
			<Route path="/support" element={<Support />} />
			{/* <Route path="/signup" element={<Signup schoolsID={schoolsID} classes={classes} />} /> */}
		</Routes>
	);
}

function LoggedInRoutes() {
	return (
		<ErrorBoundary>
			<Routes>
				<Route path="/" element={<Overview />} />
				<Route path="/404" element={<ErrorPage message="Page not found" />} />
				<Route path="/manage_classes" element={<ManageClasses />} />
				<Route path="/:classParam" element={<Overview />} />
				<Route path="/:classParam/:overviewParam" element={<Overview />} />
				<Route path="/settings" element={<Settings />} />
				<Route path="/*" element={<Overview />} />
				<Route path="/contact" element={<Contact />} />
				<Route path="/support" element={<Support />} />
				{/* TODO: kan uiteindelijk weg */}
				<Route path="/db_manager" element={<DBManager />} />
			</Routes>
		</ErrorBoundary>
	);
}
