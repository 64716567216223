import { FirebaseManager } from "../firebase/FirebaseManager";
import axios from "axios";

export default async function deleteTargetFromClass(targetID: string, classID: string) {
	console.log("deleteTargetFromClass called");
	try {
		const idToken = await FirebaseManager.getAuthIdToken();
		await axios.put(
			"https://auris-db-backend.vercel.app/deleteTargetFromClass",
			{
				ClassID: classID,
				TargetID: targetID,
			},
			{
				headers: {
					Authorization: idToken,
				},
			}
		);
	} catch (error) {
		throw error;
	}
}
