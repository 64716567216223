import axios from "axios";
import { FirebaseManager } from "../firebase/FirebaseManager";

// TODO?: adjust to TEACHER
export default async function changeClassNameAPI(ClassID: string, newClassName: string) {
	console.log("changeClassNameAPI called");
	try {
		const idToken = await FirebaseManager.getAuthIdToken();
		await axios.put(
			`https://auris-db-backend.vercel.app/changeClassName`,
			{
				id: ClassID,
				newClassName: newClassName,
			},
			{
				headers: {
					Authorization: idToken,
				},
			}
		);
	} catch (error) {
		throw error;
	}
}
