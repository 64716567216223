import axios from "axios";
import * as t from "../../data/types";
import { FirebaseManager } from "../firebase/FirebaseManager";

export default async function editStudentAPI(studentData: t.User) {
	console.log("API CALL: editStudentAPI");
	try {
		const idToken = await FirebaseManager.getAuthIdToken();
		await axios.put("https://auris-db-backend.vercel.app/editStudent", studentData, {
			headers: { Authorization: idToken },
		});
	} catch (error) {
		throw error;
	}
}
