import axios from "axios";
import React from "react";
import * as t from "../../data/types";
import * as e from "../../data/enums";
import { FirebaseManager } from "../firebase/FirebaseManager";

export default async function getClassesByTeacherAPI(
	teacherID: string,
	setClassData: React.Dispatch<React.SetStateAction<t.Class[] | null>>
) {
	console.log("getClassesByTeacherAPI called");
	try {
		const idToken = await FirebaseManager.getAuthIdToken();
		const res = await axios.get(`https://auris-db-backend.vercel.app/getClassesByTeacher?teacherId=${teacherID}`, {
			headers: {
				Authorization: idToken,
			},
		});
		const classes = res.data;

		// Calculate average progress per module per class
		const modules = Object.values(e.Modules) as e.Modules[];
		for (const c of classes) {
			const avgProgress: number[] = [];
			for (const module of modules) {
				if (!c.Students || c.Students.length === 0) {
					avgProgress.push(0);
					continue;
				}
				const classProgress = c.Students.reduce((acc: any, student: t.User) => {
					const moduleProgress = student.Progress.modules[module]?.ModuleTotal || 0;
					return acc + moduleProgress;
				}, 0);
				if (!classProgress) avgProgress.push(0);
				else avgProgress.push(Math.round(classProgress / c.Students.length));
			}
			c.AverageProgress = avgProgress;
			// console.log(c.ClassName, c.AverageProgress);
		}

		if (classes) setClassData(classes);
		else console.log("No classes found for teacher", teacherID);
		localStorage.setItem("classes", JSON.stringify(classes));
	} catch (error) {
		throw error;
	}
}
