import * as t from "../../data/types";
import * as e from "../../data/enums";
import * as c from "../../data/constants/constants";
import * as image from "../../data/constants/images";
import React, { useMemo } from "react";
import { convertDateToString } from "../../utils/dates";
import { spaceBeforeCapitals } from "../../utils/sharedUtils";
import { calculateTargetProgress } from "../Students/Students";
import { CommonModal, ModalNavigation } from "../../components/CommonModal";
import { calculateDaysLeft } from "./utils";
import useTableSort from "../../hooks/useTableSort";

export default function TargetModal({
	target,
	targets,
	setSelectedTarget,
	selectedClass,
	showModal,
	setShowModal,
}: {
	target: t.Target | undefined;
	targets: t.Target[] | undefined;
	setSelectedTarget: React.Dispatch<React.SetStateAction<t.Target | undefined>>;
	selectedClass: t.Class | undefined;
	showModal: boolean;
	setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element {
	if (!target || !targets) return <></>;

	const targetIndex = targets?.findIndex((s) => s.id === target?.id);
	const navigateTarget = (direction: "left" | "right") => {
		const newIndex = direction === "left" ? targetIndex - 1 : targetIndex + 1;
		if (newIndex < 0 || newIndex > targets.length - 1) return;
		setSelectedTarget(targets[newIndex]);
	};

	return (
		<CommonModal showModal={showModal} setShowModal={setShowModal}>
			<ModalNavigation
				currentIndex={targetIndex}
				totalItems={targets.length}
				onNavigate={navigateTarget}
				navigationLabel={spaceBeforeCapitals(target.Module)}
			/>
			<TargetInfo target={target} selectedClass={selectedClass} />
			<TargetTable target={target} selectedClass={selectedClass} />
		</CommonModal>
	);
}

function TargetInfo({ target, selectedClass }: { target: t.Target; selectedClass: t.Class | undefined }) {
	const daysLeft = calculateDaysLeft(target.Deadline);

	let levelName = c.LEVELS[target.Module][target.Difficulty][target.SectionIndex].levels[target.LevelIndex].levelName;

	if (target === undefined || target.Module === undefined || target.LevelID === undefined) return <></>;
	return (
		<div className="statsCards">
			<div className="card">
				<img className="streakImg" src={image.ICONS[target.Module]} alt="streak" />
				<div className="cardText">
					<h2>{spaceBeforeCapitals(target.Difficulty)}</h2>
					<p>Difficulty</p>
				</div>
			</div>

			<div className="card">
				<img className="streakImg" src={image.ICONS[e.Icons.TARGET]} alt="streak" />
				<div className="cardText">
					<h2>{target.Percentage}%</h2>
					<p>Target percentage</p>
				</div>
			</div>

			<div className="card">
				<img className="streakImg" src={image.ICONS[e.Icons.CUSTOMIZE]} alt="streak" />
				<div className="cardText">
					<h2>Section {target.SectionIndex}</h2>
					<p>{levelName}</p>
				</div>
			</div>

			<div className="card">
				<img className="streakImg" src={image.ICONS[e.Icons.MONTHLY]} alt="streak" />
				<div className="cardText">
					<div className="flexRow">
						<h2>{convertDateToString(target?.Deadline, "dd-mm-yyyy")}</h2>
						<p>({daysLeft} days left)</p>
					</div>
					<p>deadline</p>
				</div>
			</div>
		</div>
	);
}

function TargetTable({ target, selectedClass }: { target: t.Target; selectedClass: t.Class | undefined }) {
	const tableData = useMemo(() => parseTableTargetData(selectedClass?.Students, target), [target]);
	const { sortedData: sortedTargets, handleSort, sortConfig } = useTableSort(tableData);

	return (
		<div className="overviewTable">
			<table>
				<thead className="theadCSS">
					<tr>
						{["Name", "Progress", ""].map((header, index) => (
							<th key={index} onClick={() => handleSort(index)} style={{ cursor: "pointer" }}>
								{header}
								{sortConfig &&
									sortConfig.index === index &&
									(sortConfig.direction === "asc" ? " ▲" : " ▼")}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{sortedTargets.map((item, index) => (
						<tr key={index} className="clickableTableRow">
							<td>{spaceBeforeCapitals(item[0])}</td>
							<td style={{ width: "50%" }}>
								{item[1] !== undefined && (
									<div className="progressBar">
										<div className="progress" style={{ width: `${item[1]}%` }}></div>
									</div>
								)}
							</td>
							<td> {`${item[1]}%`}</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
}

export function parseTableTargetData(students: t.User[] | undefined, target: t.Target): string[][] {
	const targetData: string[][] = [];
	if (!students || !target) return targetData;

	students.map((student) => {
		const name = student.FirstName + " " + student.LastName;
		const progress = calculateTargetProgress([target], student);
		// console.log(name, progress);
		targetData.push([name, progress]);
		return null;
	});
	return targetData;
}
