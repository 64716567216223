import React, { useState } from "react";
import { useTeacherLoggedIn } from "../context/UserContext";
import { FirebaseManager } from "../services/firebase/FirebaseManager";
import Loading from "../components/Loading";
import { useTranslation } from "react-i18next";
import { useAlert } from "../context/AlertContext";
import * as e from "../data/enums";
import * as img from "../data/constants/images";
import i18next from "i18next";

export enum ToggleContent {
	CHANGE_PASSWORD,
	CHANGE_LANGUAGE,
}

export default function Settings(): JSX.Element {
	const [visibleContent, setVisibleContent] = useState<ToggleContent | undefined>();
	const { t } = useTranslation("Settings");

	const handleToggleClick = (content: ToggleContent) => {
		if (visibleContent === content) setVisibleContent(undefined);
		else setVisibleContent(content);
	};
	return (
		<main className="settings">
			<h1>{t("settings")}</h1>
			<PersonalData />
			<ChangePassword
				isVisible={visibleContent === ToggleContent.CHANGE_PASSWORD}
				onClick={() => handleToggleClick(ToggleContent.CHANGE_PASSWORD)}
				close={() => setVisibleContent(undefined)}
			/>
			<Language
				isVisible={visibleContent === ToggleContent.CHANGE_LANGUAGE}
				onClick={() => handleToggleClick(ToggleContent.CHANGE_LANGUAGE)}
				close={() => setVisibleContent(undefined)}
			/>
		</main>
	);
}

function PersonalData(): JSX.Element {
	const { teacher } = useTeacherLoggedIn();
	const { t } = useTranslation("Settings");

	return (
		<div className="progressCard">
			<table>
				<tbody>
					<tr>
						<td>{t("name")}</td>
						<td>: {teacher.FirstName + " " + teacher.LastName}</td>
					</tr>
					<tr>
						<td>{t("email")}</td>
						<td>: {teacher.Email}</td>
					</tr>
					<tr>
						<td>{t("school")}</td>
						<td>: {teacher.SchoolName}</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
}

function ChangePassword({
	isVisible,
	onClick,
	close,
}: {
	isVisible: boolean;
	onClick: () => void;
	close: () => void;
}): JSX.Element {
	const { teacher } = useTeacherLoggedIn();
	const { triggerAlert } = useAlert();
	const { t } = useTranslation("Settings");

	const [oldPassword, setOldPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [newPasswordCheck, setNewPasswordCheck] = useState("");
	const [errorMessage, setErrorMessage] = useState<string>("");
	const [loading, setLoading] = useState<boolean>(false);

	async function handleChangePassword() {
		if (newPassword !== newPasswordCheck) return setErrorMessage(t("passwordsDontMatch"));

		setLoading(true);

		try {
			await FirebaseManager.updatePassword(oldPassword, newPassword);
			triggerAlert(t("passwordUpdated"), "success");
			resetFields();
		} catch (error) {
			setErrorMessage(t("passwordChangeError"));
		} finally {
			setLoading(false);
		}
	}

	function resetFields() {
		setOldPassword("");
		setNewPassword("");
		setNewPasswordCheck("");
		close();
	}

	return (
		<>
			{loading && <Loading />}
			<div className="progressCard">
				<div className="toggleTrigger link" onClick={onClick}>
					{t("changePassword")}
				</div>
				{isVisible && (
					<form
						className="toggleContent"
						id="changePassword"
						onSubmit={(e) => {
							e.preventDefault();
							handleChangePassword();
						}}
					>
						<br />
						<label className="displayNone">
							Username
							<input id="Username" type="text" value={teacher.Email} readOnly autoComplete="username" />
						</label>
						<label>
							{t("oldPassword")}
							<input
								id="oldPassword"
								type="password"
								value={oldPassword}
								onChange={(e) => setOldPassword(e.target.value)}
								required
								autoComplete="current-password"
							/>
						</label>
						<label>
							{t("newPassword")}
							<input
								id="newPassword"
								type="password"
								value={newPassword}
								onChange={(e) => setNewPassword(e.target.value)}
								required
								autoComplete="new-password"
							/>
						</label>
						<label>
							{t("repeatNewPassword")}
							<input
								id="newPasswordCheck"
								type="password"
								value={newPasswordCheck}
								onChange={(e) => setNewPasswordCheck(e.target.value)}
								required
								autoComplete="new-password"
							/>
						</label>
						{errorMessage && <p className="error">{errorMessage}</p>}
						<button type="submit">{t("changePassword")}</button>
					</form>
				)}
			</div>
		</>
	);
}

function Language({ isVisible, onClick, close }: { isVisible: boolean; onClick: () => void; close: () => void }) {
	const { t } = useTranslation("Settings");

	const languages = Object.values(e.Languages) as e.Languages[];
	const currentLanguage = i18next.language as e.Languages;

	const handleSelectLanguage = (lang: string) => {
		if (lang === currentLanguage) return;
		i18next.changeLanguage(lang);
		localStorage.setItem("language", lang);
		// close();
	};

	return (
		<div className="progressCard">
			<h3 className="toggleTrigger link" onClick={onClick}>
				{t("changeLanguage")}
			</h3>

			{isVisible && (
				<div className="flags">
					{languages.map((lang) => (
						// <h2>{lang}</h2>
						<img
							className={currentLanguage === lang ? "flag flagActive" : "flag"}
							src={img.FLAGS[lang]}
							onClick={() => handleSelectLanguage(lang)}
							alt={lang}
							title={lang}
						/>
					))}
				</div>
			)}
		</div>
	);
}
