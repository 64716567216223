import React, { useState } from "react";
import { ImportStudentsFromCSVFile } from "./AddStudents/ImportStudentsFromCSVFile";
import CreateNewStudent from "./AddStudents/CreateNewStudent";
import { useTranslation } from 'react-i18next';

enum Action {
	createNewStudent,
	importStudents,
}

export default function AddStudents({ selectedClass }: { selectedClass: any }): JSX.Element {
	const [action, setAction] = useState(Action.importStudents);
	const { t } = useTranslation("ManageClasses");

	return (
		<>
			<div className="flexRow">
				<h3
					className={action === Action.createNewStudent ? "link disabled" : "link"}
					onClick={() => setAction(Action.createNewStudent)}
				>
					{t("addSingleStudent")}
				</h3>
				<h3
					className={action === Action.importStudents ? "link alignRight disabled" : "link alignRight"}
					onClick={() => setAction(Action.importStudents)}
				>
					{t("importCsvFile")}
				</h3>
			</div>
			{action === Action.createNewStudent && <CreateNewStudent selectedClass={selectedClass} />}
			{action === Action.importStudents && <ImportStudentsFromCSVFile classToEdit={selectedClass} />}
		</>
	);
}
