import * as e from "../enums";

export const LOGO = "/img/logo.png";

export const ICONS: Record<any, string> = {
	[e.Modules.INTERVAL_COMPARISON]: "/img/7.png",
	[e.Modules.INTERVAL_RECOGNITION]: "/img/1.png",
	[e.Modules.HARMONIC_CONTEXT]: "/img/6.png",
	[e.Modules.SCALE_RECOGNITION]: "/img/2.png",
	[e.Modules.MELODIC_DICTATION]: "/img/3.png",
	[e.Modules.CHORD_RECOGNITION]: "/img/4.png",
	[e.Modules.TRIAD_INVERSIONS]: "/img/5.png",
	[e.Modules.PROGRESSIONS_MAJOR]: "/img/8.png",
	[e.Modules.PROGRESSIONS_MINOR]: "/img/9.png",
	[e.Modules.PROGRESSIONS_JAZZ]: "/img/5.png",
	[e.Icons.EXERCISE]: "/img/headphones.png",
	[e.Icons.STATISTICS]: "/img/stats.png",
	[e.Icons.SETTINGS]: "/img/settings.png",
	[e.Icons.ASSESSMENTS]: "/img/assessments.png",
	[e.Icons.EXAM]: "/img/exam.png",
	[e.Icons.STREAK]: "/img/streak.png",
	[e.Icons.STREAK_ZERO]: "/img/streakZero.png",
	[e.Icons.LONGEST_STREAK]: "/img/longestStreak.png",
	[e.Icons.CUP]: "/img/cup.png",
	[e.Icons.TIMER]: "/img/timer.png",
	[e.Icons.GOLD]: "/img/gold-medal.png",
	[e.Icons.SILVER]: "/img/silver-medal.png",
	[e.Icons.BRONZE]: "/img/bronze-medal.png",
	[e.Icons.EYE_OPEN]: "/img/eyeOpen.png",
	[e.Icons.EYE_CLOSED]: "/img/eyeClosed.png",
	[e.Icons.ERROR_404]: "/img/error404.png",
	[e.Icons.ARROW_LEFT]: "/img/arrowLeft.png",
	[e.Icons.ARROW_RIGHT]: "/img/arrowRight.png",
	[e.Icons.MONTHLY]: "/img/monthly.png",
	[e.Icons.FLAT_ICON]: "/img/flatIcon.png",
	[e.Icons.FLAT_ICON_DARK]: "/img/flatIconDark.png",
	[e.Icons.SHARP_ICON]: "/img/sharpIcon.png",
	[e.Icons.SHARP_ICON_DARK]: "/img/sharpIconDark.png",
	[e.Icons.TARGET]: "/img/target.png",
	[e.Icons.CUSTOMIZE]: "/img/customize.png",
	[e.Icons.CSV]: "/img/csv.png",
	[e.Icons.XLS]: "/img/excel.png",
};

export const FLAGS: Record<any, string> = {
	// [e.Languages.GREECE]: "/img/flags/greece.webp",
	// [e.Languages.USA]: "/img/flags/usa.webp",
	// [e.Languages.CATALAN]: "/img/flags/catalan.webp",
	// [e.Languages.DUTCH]: "/img/flags/netherlands.webp",
	[e.Languages.ENGLISH]: "/img/flags/uk.webp",
	// [e.Languages.FINNISH]: "/img/flags/finland.webp",
	// [e.Languages.FRENCH]: "/img/flags/france.webp",
	[e.Languages.GERMAN]: "/img/flags/germany.webp",
	// [e.Languages.HINDI]: "/img/flags/india.webp",
	// [e.Languages.ITALIAN]: "/img/flags/italy.webp",
	// [e.Languages.PORTUGUESE]: "/img/flags/portugal.webp",
	// [e.Languages.SPANISH]: "/img/flags/spain.webp",
};
