import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";
import * as e from "../data/enums";

console.log("i18n initialization file loaded");

const localStorageLanguage = localStorage.getItem("language") as e.Languages;
const browserLanguage = navigator.language.split("-")[0] as e.Languages;

const userLanguage = Object.values(e.Languages).includes(localStorageLanguage)
	? localStorageLanguage
	: Object.values(e.Languages).includes(browserLanguage)
	? browserLanguage
	: e.Languages.ENGLISH;

// const userLanguage = e.Languages.ENGLISH;
// console.log("Initializing i18n with language:", userLanguage);

i18n.use(HttpBackend)
	.use(initReactI18next)
	.init({
		fallbackLng: e.Languages.ENGLISH,
		lng: userLanguage,
		debug: false,
		ns: ["/", "Header"],
		defaultNS: "/",
		interpolation: {
			escapeValue: false,
		},
		backend: {
			loadPath: "/locales/{{lng}}/{{ns}}.json",
		},
	});

export default i18n;
