import React, { useEffect, useMemo, useState } from "react";
import * as c from "../../data/constants/constants";
import * as t from "../../data/types";
import * as e from "../../data/enums";
import { createExerciseID, createUniqueID, handleError, spaceBeforeCapitals } from "../../utils/sharedUtils";
import { useTeacherLoggedIn } from "../../context/UserContext";
import getClassesByTeacherAPI from "../../services/api/getClassesByTeacherAPI";
import createNewTargetAPI from "../../services/api/createNewTargetAPI";
import { convertDateToString, getTodayDate } from "../../utils/dates";
import { useAlert } from "../../context/AlertContext";
import deleteTargetFromClass from "../../services/api/deleteTargetFromClassAPI";
import { getFutureTargets } from "./utils";
import { useTranslation } from "react-i18next";

export default function CreateNewTarget({
  selectedClass,
  selectedTarget,
  title,
}: {
  selectedClass: any;
  selectedTarget: t.Target | undefined;
  title: string;
}): JSX.Element {
  const { teacher, setClassData, setLoading } = useTeacherLoggedIn();
  const { triggerAlert, triggerConfirm } = useAlert();
  const { t } = useTranslation("Overview");

  const [module, setModule] = useState("");
  const [difficulty, setDifficulty] = useState("");
  const [level, setLevel] = useState("");
  const [section, setSection] = useState(0);
  const [deadline, setDeadline] = useState("");

  useEffect(() => {
    if (selectedTarget) {
      setModule(selectedTarget.Module);
      setDifficulty(selectedTarget.Difficulty);
      setLevel(selectedTarget.LevelIndex.toString());
      setSection(selectedTarget.SectionIndex);
      setDeadline(convertDateToString(selectedTarget.Deadline, "yyyy-mm-dd"));
    }
  }, [selectedTarget]);

  const futureTargets = getFutureTargets(selectedClass);

  const modules = useMemo(() => Object.values(e.Modules), []);

  const difficulties = useMemo(() => {
    return module ? Object.keys(c.LEVELS[module as e.Modules]) : [];
  }, [module]);

  const { ids, levelNames } = useMemo(() => {
    return getLevels(module, difficulty);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [difficulty]);

  function handleSelectModule(module: string) {
    setModule(module);
    setDifficulty("");
    setLevel("");
  }

  function handleSelectDifficulty(difficulty: string) {
    setDifficulty(difficulty);
    setLevel("");
  }

  function handleSelectLevel(levelIndex: string) {
    setLevel(levelIndex);
    setSection(findSection(ids, levelIndex));
    // console.log(findSection(ids, levelIndex), findLevelIndex(ids, levelIndex));
  }

  async function handleCreateTarget() {
    if (!module || !level || !deadline) return;
    if (
      futureTargets &&
      futureTargets.some((target: t.Target) => {
        return target.Module === module && target.Difficulty === difficulty;
      })
    )
      triggerConfirm(
        t("overwriteTargetConfirm"),
        async () => {
		// TODO: this can be done more efficient by adjusting the backend (where it always checks if a target with this module/difficulty combination already exists and in that case overwrites it)
          setLoading(true);
          const targetID = futureTargets.find((target: t.Target) => {
            return target.Module === module && target.Difficulty === difficulty;
          })!.id;
          await deleteTargetFromClass(targetID, selectedClass.id);
          await createTarget();
        }
      );
    else await createTarget();
  }

  async function createTarget() {
    const targetData: t.Target = {
      id: createUniqueID(),
      ClassID: selectedClass.id,
      Module: module as e.Modules,
      Difficulty: difficulty,
      SectionIndex: section,
      LevelIndex: findLevelIndex(ids, level),
      LevelID: ids[Number(level)],
      Percentage: calculateTargetedProgress(Number(level), levelNames.length - 1),
      Deadline: new Date(deadline),
    };
    // console.log(targetData);
    setLoading(true);
    try {
      await createNewTargetAPI(targetData);
      await getClassesByTeacherAPI(teacher.id, setClassData);
      triggerAlert(t("targetCreated"), "success");
    } catch (error) {
      triggerAlert(handleError(error).userMessage, "error");
    } finally {
      setLoading(false);
    }
  }

  async function handleDeleteTarget() {
    triggerConfirm(t("deleteTargetConfirm"), async () => {
      await deleteTarget();
    });
  }

  async function deleteTarget() {
    if (!selectedTarget || !selectedClass || !futureTargets) return;
    setLoading(true);
    try {
      await deleteTargetFromClass(selectedTarget?.id, selectedClass.id);
      await getClassesByTeacherAPI(teacher.id, setClassData);
      triggerAlert(t("targetDeleted"), "success");
    } catch (error: any) {
      triggerAlert(error.message, "error");
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="basicCard createNewContent">
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <label className="flexRow">
          <h3>{t("deadline")}</h3>
          <input
            className="text-area"
            type="date"
            value={deadline}
            min={getTodayDate()}
            onChange={(e) => setDeadline(e.target.value)}
            required
          />
        </label>
        {deadline && (
          <label className="flexRow">
            <h3>{t("module")}</h3>
            <select name="module" value={module} onChange={(e) => handleSelectModule(e.target.value)}>
              <option value="">{t("selectModule")}</option>
              {modules.map((module) => (
                <option key={module} value={module}>
                {t(spaceBeforeCapitals(module))}
                </option>
              ))}
            </select>
          </label>
        )}
        {deadline && module && difficulties.length > 0 && (
          <label className="flexRow">
            <h3>{t("difficulty")}</h3>
            <select
              name="difficulties"
              value={difficulty}
              onChange={(e) => handleSelectDifficulty(e.target.value)}
            >
              <option value="">{t("selectDifficulty")}</option>
              {difficulties.map((difficulty: any, index: number) => (
                <option key={index} value={difficulty}>
                  {spaceBeforeCapitals(difficulty)}
                </option>
              ))}
            </select>
          </label>
        )}
        {module && levelNames.length > 0 && (
          <label className="flexRow">
            <h3>{t("level")}</h3>
            <select name="level" value={level} onChange={(e) => handleSelectLevel(e.target.value)}>
              <option value="">{t("selectLevel")}</option>
              {levelNames.map((level: any, index: number) => (
				// if level contains Section, make it unclicable
                <option key={index} value={index} disabled={level.includes("Section")}>
                  {level}
                </option>
              ))}
            </select>
          </label>
        )}

        <button
          onClick={handleCreateTarget}
          className={module && level && deadline ? "" : "buttonDisabled"}
          disabled={!(module && level && deadline)}
        >
          {title}
        </button>
        {selectedTarget !== undefined && (
          <button onClick={handleDeleteTarget} className="redButton">
            {t("deleteTarget")}
          </button>
        )}
      </form>
    </div>
  );
}

const calculateTargetedProgress = (level: number, totalLevels: number): number => {
  const targetedProgress = (level / totalLevels) * 100;
  return Math.round(targetedProgress);
};

type levelType = {
  levelName: string;
  options: string[];
};
type sectionType = {
  sectionName: string;
  levels: levelType[];
};

// TODO: also check for duplicate levels in intervalRecognitionOctaveHigher.ts
function getLevels(module: string, difficulty: string) {
  if (module === "" || difficulty === "") return { ids: [], levelNames: [] };
  const sections: sectionType[] = Object.values(c.LEVELS[module as e.Modules][difficulty]);
  const levelNames: string[] = [];
  const ids: string[] = [];
  let count = 0;
  for (const section of sections) {
    count++;
    ids.push("Section " + count);
    levelNames.push("Section " + count + ": " + section.sectionName);
    const levels = section.levels;
    for (const level of levels) {
      ids.push(createExerciseID(module, difficulty, level.options));
      levelNames.push(level.levelName);
    }
  }
  return { ids, levelNames };
}

function findSection(ids: string[], levelIndex: string) {
  let count = 0;
  for (let i = 0; i < Number(levelIndex); i++) if (ids[i].includes("Section")) count++;
  return Math.max(0, count - 1);
}

function findLevelIndex(ids: string[], level: string) {
  let count = 0;
  for (let i = 0; i < Number(level); i++) {
    if (ids[i].includes("Section")) count = 0;
    else count++;
  }
  return count;
}
