import React, { useState } from "react";
import { AxiosError } from "axios";
import addSchoolAPI from "../../services/api/addSchoolAPI";

export default function AddSchool(): JSX.Element {
	const [schoolName, setSchoolName] = useState("");
	const [schoolCountry, setSchoolCountry] = useState("");
	const [schoolEmailDomain, setSchoolEmailDomain] = useState("");

	const addSchool = async () => {
		try {
			const response = await addSchoolAPI(schoolName, schoolCountry, schoolEmailDomain);
			if (response.status === 200) {
				alert("School added successfully");
				window.location.reload();
			}
		} catch (error: any) {
			const axiosError = error as AxiosError;
			if (axiosError.response && axiosError.response.status === 400) alert("School already exists");
			console.error(error);
		}
	};

	return (
		<div className="basicCard">
			<h1>Add School</h1>
			<input
				className="text-area"
				type="text"
				placeholder="School Name"
				onChange={(e) => setSchoolName(e.target.value)}
			/>
			<br /> <br />
			<input
				className="text-area"
				type="text"
				placeholder="Country"
				onChange={(e) => setSchoolCountry(e.target.value)}
			/>
			<br /> <br />
			<input
				className="text-area"
				type="email"
				placeholder="@schoolname.com"
				onInput={(e: any) => {
					const lowercaseValue = e.target.value.toLowerCase();
					e.target.value = lowercaseValue;
					setSchoolEmailDomain(lowercaseValue);
				}}
			/>
			<br /> <br />
			<button onClick={addSchool}>Add School</button>
		</div>
	);
}
