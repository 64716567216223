import axios from "axios";
import * as t from "../../data/types";

export default async function createStudentAPI(studentData: t.User) {
	console.log("API CALL: createStudentAPI");
	try {
		await axios.post("https://auris-db-backend.vercel.app/addStudent", studentData);
	} catch (error) {
		throw error;
	}
}
