import axios from "axios";
// import * as t from "../../data/types";
import { FirebaseManager } from "../firebase/FirebaseManager";

export default async function addSchoolAPI(schoolName: string, schoolCountry: string, schoolEmailDomain: string) {
	console.log("addClassAPI called");

	try {
		const schoolData = {
			SchoolName: schoolName,
			SchoolCountry: schoolCountry,
			EmailDomain: schoolEmailDomain,
		};
		const idToken = await FirebaseManager.getAuthIdToken();
		const res = await axios.post("https://auris-db-backend.vercel.app/addSchool", schoolData, {
			headers: {
				Authorization: idToken,
			},
		});
		return res;
	} catch (error: any) {
		throw error;
	}
}
